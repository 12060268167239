import { DateTime } from 'luxon';
import { fp } from '../fp';
import { AVAILABLE_PRODUCTS, InsuranceProvider, INSURANCE_PROVIDERS, Product } from 'common.interfaces';
import { isEmpty } from '../helper/helper';

/* Display number in American format e.g. 2,680 */
export function numberWithCommas(x: number | string): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function numberToPrice(x: number | string): string {
  return `$${numberWithCommas(x)}`;
}

/* Removes the first occurrence of $ character in a string */
export const removeDollarCharacter = (str: string | undefined): string | undefined => {
  return str ? str.replace('$', '') : undefined;
};

export const removeCommaNumberFormatting = (str: string | undefined): string | undefined => {
  return str ? str.replaceAll(',', '') : undefined;
};

export const trimAllSpaces = (str: string | undefined): string | undefined => {
  return str ? str.replaceAll(' ', '') : undefined;
};

export const priceToNumber = (price: string | number | undefined): number => {
  if (!isNaN(Number(price))) {
    return Number(price);
  }
  return price
    ? fp.pipe(removeDollarCharacter, removeCommaNumberFormatting, trimAllSpaces, Number)(price as string)
    : 0;
};

/* Removes the first occurrence of # character in a string */
export const removeHashCharacter = (str: string): string | undefined => {
  return str ? str.replace('#', '') : undefined;
};

/* Capitalize the first letter of a string */
export const capitalizeFirstLetter = (str: string): string | undefined => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : undefined;
};

export const extractBaseUrl = (url: string): string => {
  if (url.indexOf('?') > 0) {
    return url.split('?')[0];
  }
  return url.split('#')[0];
};

export const extractBasePathFromUrl = (url: string): string => {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return new URL(url).pathname;
  }
  return extractBaseUrl(url);
};

export const extractQueryParamsFromUrl = (url: string, withSeparator = false): string => {
  const splittedUrl = url.split('?');
  if (splittedUrl.length === 1) {
    return '';
  }
  const params = splittedUrl[1].split('#')[0] ?? '';
  return withSeparator && params ? `?${params}` : params;
};

export const extractHashParamsFromUrl = (url: string, withSeparator = false): string => {
  const splittedUrl = url.split('#');
  if (splittedUrl.length === 1) {
    return '';
  }
  const params = splittedUrl[1] ?? '';
  return withSeparator && params ? `#${params}` : params;
};

export const getHashParamFromUrl = (url: string, param: string): string | undefined => {
  const hashParams = extractHashParamsFromUrl(url).split('&');
  const matchingParam = hashParams.find((p) => p.startsWith(`${param}=`));
  if (!matchingParam) {
    return;
  }
  return matchingParam.split('=')[1];
};

export const withTrailingSlash = (str: string | null | undefined): string => {
  if (!str) {
    return '/';
  }
  const lastCharacter = str.split('').reverse()[0];
  return lastCharacter === '/' ? str : str + '/';
};

export const withStartingSlash = (str: string | undefined): string => {
  if (!str) {
    return '/';
  }
  return str.startsWith('/') ? str : `/${str}`;
};

export const withoutStartingSlash = (str: string | undefined): string => {
  if (!str) {
    return '';
  }
  return str.startsWith('/') ? str.split('').splice(1).join('') : str;
};

export const withoutTrailingSlash = (str: string | undefined): string => {
  if (!str) {
    return '';
  }
  const lastCharacter = str.split('').reverse()[0];
  if (lastCharacter !== '/') {
    return str;
  }
  return str
    .split('')
    .slice(0, str.length - 1)
    .join('');
};

export const toTwoDigitNumber = (n: number): string => {
  return n < 10 ? `0${n}` : `${n}`;
};

export const isDurationInHours = (durationInSeconds: number) => durationInSeconds >= 3600;

interface FormatDurationOptions {
  forceHours?: boolean;
  withLetterSeparator?: boolean;
}

export const formatDuration = (durationInSeconds: number, opts?: FormatDurationOptions): string => {
  const forceHours = opts?.forceHours ?? false;
  const secondsSuffix = opts?.withLetterSeparator ? 's' : '';
  const minutesSeparator = opts?.withLetterSeparator ? 'min' : ':';
  const hoursSeparator = opts?.withLetterSeparator ? 'h' : ':';

  const seconds = Math.floor(durationInSeconds % 60);
  const minutes = Math.floor(durationInSeconds / 60) % 60;
  const hours = Math.floor(durationInSeconds / 3600);

  const formattedMinutesAndSeconds = `${toTwoDigitNumber(minutes)}${minutesSeparator}${toTwoDigitNumber(
    seconds,
  )}${secondsSuffix}`;
  if (forceHours || hours !== 0) {
    return `${toTwoDigitNumber(hours)}${hoursSeparator}${formattedMinutesAndSeconds}`;
  }
  return formattedMinutesAndSeconds;
};
/**
 * Converts a string date to a DateTime object with value read in local timezone.
 * Input datetime format: 'YYYY-MM-DDTHH:MM:SS.mmmZ'
 * @param dateTimeString: string
 * @returns DateTime
 */
export const convertStringToISODate = (dateTimeString: string): DateTime => {
  const isLuxonValid = DateTime.fromISO(dateTimeString).isValid;
  return isLuxonValid
    ? DateTime.fromISO(dateTimeString, { zone: 'local' })
    : DateTime.fromJSDate(new Date(dateTimeString), { zone: 'local' });
};

export const startWithSlash = (str: string | undefined): string => {
  if (!str) {
    return '/';
  }
  return str.indexOf('/') === 0 ? str : `/${str}`;
};

export const sortByProperty = <T extends object>(list: T[], key: keyof T, asc = true): T[] => {
  return [...list].sort((a, b) => {
    if (a[key] > b[key]) {
      return asc ? 1 : -1;
    }
    if (a[key] < b[key]) {
      return asc ? -1 : 1;
    }
    return 0;
  });
};

export const sortBy =
  <T extends object>(key: keyof T) =>
  (list: T[], asc = true) =>
    sortByProperty(list, key, asc);

export const sortAscBy =
  <T extends object>(key: keyof T) =>
  (list: T[]): T[] =>
    sortByProperty(list, key, true);

export const sortDescBy =
  <T extends object>(key: keyof T) =>
  (list: T[]): T[] =>
    sortByProperty(list, key, false);

export const sortByIsoDateProperty = <T extends object>(list: T[], key: keyof T, asc = true): T[] => {
  return [...list].sort((a, b) => {
    const startDateA = a[key] ? DateTime.fromISO(a[key] as unknown as string) : DateTime.now();
    const startDateB = b[key] ? DateTime.fromISO(b[key] as unknown as string) : DateTime.now();
    if (startDateA.startOf('day') > startDateB.startOf('day')) {
      return asc ? 1 : -1;
    }
    if (startDateA.startOf('day') < startDateB.startOf('day')) {
      return asc ? -1 : 1;
    }
    return 0;
  });
};

export const sortListDescByIsoDateProperty =
  <T extends object>(key: keyof T) =>
  (list: T[]): T[] =>
    sortByIsoDateProperty(list, key, false);
export const sortListAscByIsoDateProperty =
  <T extends object>(key: keyof T) =>
  (list: T[]): T[] =>
    sortByIsoDateProperty(list, key, true);

export const getMatchingInsuranceProvider = (carrier: string): InsuranceProvider | undefined => {
  return INSURANCE_PROVIDERS.find((i) => i.toLocaleLowerCase() === carrier.toLocaleLowerCase());
};
export const getMatchingProduct = (product: string): Product | undefined => {
  return AVAILABLE_PRODUCTS.find((i) => i.toLocaleLowerCase() === product.toLocaleLowerCase());
};

export const createQueryParamsString = (queryObject: { [key: string]: string | undefined }): string => {
  if (isEmpty(queryObject)) {
    return '';
  }

  let result = '/?';

  for (const key in queryObject) {
    if (queryObject[key]) {
      result += key + '=' + queryObject[key] + '&';
    }
  }

  return result.length === 2 ? '' : result.slice(0, -1); // Remove the trailing '&'
};

export const appendPathToUrl = (url: string, path: string | undefined): string => {
  if (!path) {
    return url;
  }
  const queryParams = extractQueryParamsFromUrl(url, true);
  const hashParams = extractHashParamsFromUrl(url, true);
  const baseUrl = extractBaseUrl(url);

  const fullPath = withTrailingSlash(baseUrl) + withoutStartingSlash(path);
  return fullPath + queryParams + hashParams;
};

export const roundSecondsToHoursNearestFifteenMinutes = (seconds: number): number => {
  const secondsToHours = seconds / 60 / 60;
  const hours = Math.floor(secondsToHours);
  const remaining = secondsToHours % 1;
  const nearest15minRatio = 0.125;
  /* Rounding to the nearest 15 minutes */
  if (remaining < nearest15minRatio) {
    // < 7.5 minutes
    return hours;
  } else if (remaining < 3 * nearest15minRatio) {
    // > 7.5 minutes && < 22.5 minutes
    return hours + 0.25;
  } else if (remaining < 5 * nearest15minRatio) {
    // > 22.5 minutes minutes && < 37.5
    return hours + 0.5;
  } else if (remaining < 7 * nearest15minRatio) {
    // > 37.5 minutes && < 52.5 minutes
    return hours + 0.75;
  }
  // > 52.5 minutes
  return hours + 1;
};

export const lowerFirstLetter = (str: string) => {
  if (!str) {
    return '';
  }
  return str[0].toLowerCase() + str.split('').slice(1, str.length).join('');
};

export const isStringDateTime = (str: any) => {
  if (typeof str !== 'string') {
    return false;
  }
  /*
  We accept only datetime that start with YYYY-MM-DD, string that starts with YYYY-MM or YYYY will not be considered as Date
  */
  const datePattern = new RegExp(/^(\d{4})-(\d{2})-(\d{2})/);
  return datePattern.test(str) && DateTime.fromISO(str).isValid;
};
