// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  pathToSource: 'browser',
  trackingId: 'bvowph/admin-manager',
  securedGraphQlGateway: 'https://gateway-private.dev.o8cares.com/federated-gql',
  lpBasePath: 'https://landing-pages.origin8cares.com/',
  auth: {
    domain: 'auth.dev.o8cares.com',
    clientId: 'NmgS5NeX9RqOF5xztCZRghrIwNCqjbN4',
    audience: 'Permission API',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
