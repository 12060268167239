import { LpVisit } from 'common.interfaces';
import { AbExperiment } from 'common.interfaces';

export enum AB_EXPERIMENT_MANAGER_ROUTES {
  BASE = 'manage-experiments',
  FULL_BASE = '/manage-experiments',
  CREATE = 'create',
  FULL_CREATE = '/manage-experiments/create',
  GET_EXPERIMENT = 'get-experiment/:id',
  FULL_GET_EXPERIMENT = '/manage-experiments/get-experiment/:id',
  GET_URL_METRIC = 'get-url-metric/:lp',
  FULL_GET_URL_METRIC = '/manage-experiments/get-url-metric/:lp',
}

export const abExpStateKey = 'abExperiments';

export type AbExpState = {
  visits: LpVisit[];
  experiments: AbExperiment[];
};
