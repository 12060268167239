import { Auth0ClientOptions, GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';

/**
 * Configuration for a single interceptor route
 */
export interface O8AuthHttpInterceptorRouteConfig {
  /**
   * The URL to test, by supplying the URL to match.
   * If `test` is a match for the current request path from the HTTP client, then
   * an access token is attached to the request in the
   *  ["Authorization" header](https://tools.ietf.org/html/draft-ietf-oauth-v2-bearer-20#section-2.1).
   *
   * If the test does not pass, the request proceeds without the access token attached.
   *
   * A wildcard character can be used to match only the start of the URL.
   *
   * @usagenotes
   *
   * '/api' - exactly match the route /api
   * '/api/*' - match any route that starts with /api/
   */
  uri?: string;

  /**
   * A function that will be called with the HttpRequest.url value, allowing you to do
   * any kind of flexible matching.
   *
   * If this function returns true, then
   * an access token is attached to the request in the
   *  ["Authorization" header](https://tools.ietf.org/html/draft-ietf-oauth-v2-bearer-20#section-2.1).
   *
   * If it returns false, the request proceeds without the access token attached.
   */
  uriMatcher?: (uri: string) => boolean;

  /**
   * The options that are passed to the SDK when retrieving the
   * access token to attach to the outgoing request.
   */
  tokenOptions?: GetTokenSilentlyOptions;

  /**
   * The HTTP method to match on. If specified, the HTTP method of
   * the outgoing request will be checked against this. If there is no match, the
   * Authorization header is not attached.
   *
   * The HTTP method name is case-sensitive.
   */
  httpMethod?: string;

  /**
   * Allow the HTTP call to be executed anonymously, when no token is available.
   *
   * When omitted (or set to false), calls that match the configuration will fail when no token is available.
   */
  allowAnonymous?: boolean;
}

export interface O8AuthConfig extends Auth0ClientOptions {
  /**
   * By default, if the page URL has code and state parameters, the SDK will assume they are for
   * an Auth0 application and attempt to exchange the code for a token.
   * In some cases the code might be for something else (e.g. another OAuth SDK). In these
   * instances you can instruct the client to ignore them by setting `skipRedirectCallback`.
   *
   * ```js
   * AuthModule.forRoot({
   *   skipRedirectCallback: window.location.pathname === '/other-callback'
   * })
   * ```
   *
   * **Note**: In the above example, `/other-callback` is an existing route that will be called
   * by any other OAuth provider with a `code` (or `error` in case when something went wrong) and `state`.
   *
   */
  skipRedirectCallback?: boolean;

  /**
   * Configuration for the built-in Http Interceptor, used for
   * automatically attaching access tokens.
   */
  httpInterceptor?: O8AuthHttpInterceptorRouteConfig;

  /**
   * Path in your application to redirect to when the Authorization server
   * returns an error. Defaults to `/`
   */
  errorPath?: string;
}

export const AUTH_PATH = {
  LOGIN: 'login',
  LOGIN_FULL_PATH: '/login',
  REDIRECT: 'login-redirect',
  REDIRECT_FULL_PATH: '/login-redirect',
  FAIL: 'login-failure',
  FAIL_FULL_PATH: '/login-failure',
  LOGOUT: 'logout',
  LOGOUT_FULL_PATH: '/logout',
  IMPERSONATION: 'impersonation',
  IMPERSONATION_FULL_PATH: '/impersonation',
};
