import { setContext } from '@apollo/client/link/context';

import { GraphQlUtils } from '../graphql.utils';

export const getAuthMiddleware = (graphQlUtils: GraphQlUtils) =>
  setContext(async (_, { headers = {} }) => {
    const token = await graphQlUtils.getAccessToken();
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
