/* Checks if a given object or array is empty */
export function isEmpty(x: object | Array<any> | undefined | null): boolean {
  if (!x) {
    return true;
  }
  return Object.keys(x).length === 0;
}

export const isDevOrQaHostname = (hostname: string) => {
  const nonProdHostname = ['localhost', 'web.dev.origin8cares', 'web.uat.origin8cares'];
  const nonProdEndingHostname = ['.dev.origin8cares.com', '.uat.origin8cares.com'];
  return nonProdHostname.some((h) => hostname.startsWith(h)) || nonProdEndingHostname.some((h) => hostname.endsWith(h));
};

export const isProdHostname = (hostname: string) => !isDevOrQaHostname(hostname);

export function filterDuplicatesByKey<T extends object>(array: Array<T>, key: keyof T): Array<T> {
  return [...new Map(array.map((item) => [item[key], item])).values()];
}

export const isArrayOfString = (value: unknown): boolean => {
  if (Array.isArray(value)) {
    return !value.some((e) => typeof e !== 'string');
  }
  return false;
};
