import { ApolloLink, HttpLink } from '@apollo/client/core';
import { GraphQlUtils } from './graphql.utils';

import { getCleanTypeNameMiddleware } from './middleware/typename.middleware';
import { getAuthMiddleware } from './middleware/auth.middleware';
import { getApolloCache } from './apollo-cache';
import { GraphQlClientLoader, GraphqlConfigProvider } from './graphql-client.interface';
import { createGraphqlClient } from './graphql-client';
import fetch from 'cross-fetch';
import { errorLoggerLink } from './middleware/error-logger.middleware';
import { getTrackerMiddleware } from '../tracking/tracker.graphql.middleware';
import { getO8GqlParserMiddleware } from './middleware/o8-gql-parser.middleware';

export function createApollo(graphQlUtils: GraphQlUtils, config: GraphqlConfigProvider): GraphQlClientLoader {
  // https://www.apollographql.com/docs/react/api/link/apollo-link-http/
  const httpLinkAnonymous = new HttpLink({
    uri: config().anonymousGraphqlEndpoint,
    fetch,
  });
  const httpLinkSecured = new HttpLink({
    uri: config().securedGraphqlEndpoint,
    fetch,
  });
  return {
    getAnonymousClient: () =>
      createGraphqlClient({
        link: ApolloLink.from([getCleanTypeNameMiddleware(), errorLoggerLink, httpLinkAnonymous]),
        cache: getApolloCache(),
      }),
    getSecuredClient: () =>
      createGraphqlClient({
        link: ApolloLink.from([
          getCleanTypeNameMiddleware(),
          getAuthMiddleware(graphQlUtils),
          getTrackerMiddleware(graphQlUtils),
          errorLoggerLink,
          getO8GqlParserMiddleware(),
          httpLinkSecured,
        ]),
        cache: getApolloCache(),
      }),
  };
}
