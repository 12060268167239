import { onError } from '@apollo/client/link/error';

// Log any GraphQL errors or network error that occurred
export const errorLoggerLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, path }) =>
      console.error(`[GraphQL error] for [${operation.operationName}]: Message: ${message}, Path: ${path}`),
    );
  if (networkError) console.error(`[Network error]  for [${operation.operationName}]: ${networkError}`);
});
