import { Observable } from 'rxjs';
import { ConversionType } from './leads';

export enum AbExperimentStatus {
  DRAFT = 'DRAFT',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
}

export interface AbVariant {
  name: string;
  weight: number;
  visits: number;
  conversions: number;
  winner: boolean;
}

export interface ConversionInfo {
  type: ConversionType;
  visitorId: string;
  queryParams: string;
  timestamp: Date;
}
export type ConversionVariantInfo = ConversionInfo & { name: string };

export type ConversionVariantInfoWithoutTimestamp = Omit<ConversionVariantInfo, 'timestamp'>;

export interface AbExperiment {
  id: string;
  url: string;
  name: string;
  hypothesis: string;
  startDate: Date | null; // YYYY-MM-DD
  endDate: Date | null; // YYYY-MM-DD
  status: AbExperimentStatus;
  variants: Array<AbVariant>;
  createdAt?: Date;
  updatedAt?: Date;
}

export type AbVariantCounter = keyof Pick<AbVariant, 'visits' | 'conversions'>;

export type NewVariantDto = Pick<AbVariant, 'name'>;

export type CreateAbExperimentDTO = Pick<AbExperiment, 'url' | 'name' | 'hypothesis'> & {
  variants: Array<NewVariantDto>;
};

export interface LpVariation {
  name: string | undefined;
  visitorId: string;
}

export interface LpVisit {
  id: string;
  visitorId: string;
  url: string;
  variation: string;
  queryParams: string;
  timestamp: Date;
  conversion?: {
    type: string;
  } | null;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface UrlMetric {
  url: string;
  variation: string;
  visits: { visitorId: string; timestamp: Date }[];
  conversions: ConversionInfo[];
  disabled: boolean;
}
