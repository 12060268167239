import { Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { authRoutes, canActivateAuth } from '@origin8-web/core-ui/login/routes';
import { AB_EXPERIMENT_MANAGER_ROUTES } from '../features/ab-experiment-manager/ab-experiment-manager.interfaces';

export const appRoutes: Routes = [
  {
    path: 'onboarding',
    canActivate: [canActivateAuth],
    loadComponent: () => import('../features/onboarding/onboarding.component').then((m) => m.OnboardingComponent),
  },
  {
    path: AB_EXPERIMENT_MANAGER_ROUTES.BASE,
    canActivate: [canActivateAuth],
    loadChildren: () =>
      import(/* webpackChunkName: "ab-module" */ '../features/ab-experiment-manager/ab-experiment-manager.module').then(
        (m) => m.AbExperimentManagerModule,
      ),
  },
  ...authRoutes,
  { path: '', pathMatch: 'full', component: HomeComponent, canActivate: [canActivateAuth] },
  {
    path: '**',
    redirectTo: '',
  },
];
